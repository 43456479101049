.ant-btn-primary,
.ant-btn-default {
  box-shadow: unset;
}

.ant-btn-default {
  background: transparent;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout .ant-layout-sider,
.ant-layout .ant-layout-header {
  background: initial;
}

.ant-select-selector,
.ant-picker-focused {
  box-shadow: unset !important;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.ant-modal .ant-modal-close:hover {
  background-color: unset;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus {
  color: white !important;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: white;
}

.ant-typography {
  margin: unset !important;
  display: flex;
  align-items: flex-start;
  padding-top: 17px;
}

.ant-select-selection-item {
  color: #000;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  border: unset !important;
}

.ant-table-thead .ant-table-cell::before {
  background-color: #f0f0f0 !important;
}

.ant-notification-notice-wrapper {
  border-radius: 20px !important;
}

.custom-no-icon-notification .ant-notification-notice-icon {
  display: none !important;
}
.custom-no-icon-notification .ant-notification-notice-message {
  margin: 0 !important;
}
